import React, { Component } from 'react'
import { Button, Navbar, Nav, NavDropdown, Form, FormControl, Container } from 'react-bootstrap';


export default class ContactUs extends Component {
    
    
  render() {
    return (
      <div style={{padding:'40px'}}>
          <div style={{width:'100%'}}>

          <h1>Haripur K.O. Sr. High Madrasah</h1>
          <br/><br/>
<h2>Contact Information</h2>
<p>Haripur K.O. Sr. High Madrasah</p>
<p>Contact Person: Azizur Rahman</p>
<p>Haripur</p>
<p>Pukhuria</p>
<p>Malda</p>
<p>West Bengal</p>
<p>732102</p>


Contact<br />

Office: +91 9733406635<br />

Admission Team: +91 9800618169<br />

E-mail: insightssoft@gmail.com


          </div>
          
      </div>
    )
  }
}
