import React, { Component } from 'react'
import { Button, Navbar, Nav, NavDropdown, Form, FormControl, Container } from 'react-bootstrap';
export default class Header extends Component {
    render() {
        return (
            <Navbar bg="light" expand="lg" sticky="top">
                <Navbar.Brand href="#home" style={{fontSize: '22px', fontWeight: '600'}}>Haripur K.O Sr Madrasah</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                    <Nav >
                    <Nav.Link href="/">Home</Nav.Link>
                    <Nav.Link href="Kanyashree">Kanyashree</Nav.Link>
                    <Nav.Link href="sms_admin">Admin Login</Nav.Link>
                    <Nav.Link href="terms_condition">Terms & Conditions</Nav.Link>
                    <Nav.Link href="Privacy">Privacy Policy</Nav.Link>
                    {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                        <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
                    </NavDropdown> */}
                    </Nav>
                    
                </Navbar.Collapse>
                </Navbar>
        )
    }
}
