import React, { Component } from 'react'
import { Button, Navbar, Nav, NavDropdown, Form, FormControl, Container } from 'react-bootstrap';


export default class AboutUs extends Component {
    
    
  render() {
    return (
      <div style={{padding:'40px'}}>
          <div style={{width:'100%'}}>

          <h1>Haripur K.O. Sr. High Madrasah</h1>

<p>Haripur K.O. Sr. High Madrasah is a built-up and understood language school, with more than fifty years of involvement in giving language courses and educator preparation programs. Situated in a roomy and completely prepared school, close to Covent Garden, we are an author individual from the International House World Organization.</p>
<h1>Vision</h1>
<p>Our vision is to be a premier educational institution which develops the all-round qualities in a student through excellence in education that shall foster academics, understanding, creativity, brilliance, spiritual well-being, physical and psychological development along with concern for one’s environment. To ensure our children become responsible global citizens with essential life skills and competencies, we provide a happy, safe and stimulating environment for your child to grow and learn.</p>
<h1>We aim to:</h1>
<p>Facilitate your child’s learning through play and work activities- both individual and group work
Provide a quality educational program that meets the individual needs at every stage of development, and also the needs of an ever-changing society
Prepare your child to be appreciative, responsible and eager for future learning.</p>

<h2>Assets</h2>

<p>In our Madrasa, education is provided from classes one to twelve. About two thousand five hundred students are studying in our Madrasa, this is our pride. We treat our students like friends</p>
<h2>Contact Information</h2>
<p>Haripur K.O. Sr. High Madrasah</p>
<p>Contact Person: Azizur Rahman</p>
<p>Haripur</p>
<p>Pukhuria</p>
<p>Malda</p>
<p>West Bengal</p>
<p>732102</p>


Contact<br />

Office: +91 9733406635<br />

Admission Team: +91 9800618169<br />

E-mail: insightssoft@gmail.com


          </div>
          
      </div>
    )
  }
}
