import React, { Component } from 'react';
import Carousel from 'react-bootstrap/Carousel'
class Carosel extends Component {
    render() {
        return (
            <div  className="slideMargin">
            <Carousel>
                <Carousel.Item>
                    
                        <img
                        className="d-block w-100 slideMain"
                        src="img/slide0.jpg"
                        alt="First slide"
                        style={{height: "60vh"}}
                        />
                    
                    <Carousel.Caption>
                     <h2>OUR MADRASAH</h2>
                    <p>Main Gate Of Our SR Madrasah.</p> 
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                    className="d-block w-100 slideMain"
                    src="img/slide1.jpg"
                    alt="First slide"
                    style={{height: "60vh"}}
                    />
                    <Carousel.Caption>
                    <h3>MADRASAH FIELD</h3>
                    <p>Some management for  kids to play in our campus .</p> 
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                    className="d-block w-100 slideMain"
                    src="img/slide2.jpeg"
                    alt="First slide"
                    style={{height: "60vh"}}
                    />
                    <Carousel.Caption>
                    <h3>HARIPUR KO SR MADRASAH</h3>
                    <p>Some special management .</p> 
                    </Carousel.Caption>
                </Carousel.Item>
                
            </Carousel>
        </div>
        );
    }
}

export default Carosel;