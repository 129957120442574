import logo from './logo.svg';
import './App.css';
import Header from './components/Header'
import Home from './components/Home';
import Footer from './components/Footer';
import OnlineAdmission from './components/OnlineAdmission';
import OnlineAdmissionNotice from './components/OnlineAdmissionNotice';
import {Route, Switch, BrowserRouter } from "react-router-dom";
import Kanyashree from './components/Kanyashree';
import Terms_condition from './components/Terms_condition';
import Privecy from './components/Privecy';
import AboutUs from './components/AboutUs';
import ContactUs from './components/ContactUs';

function App() {
  return (
    <>
      <Header />
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/Kanyashree" component={Kanyashree} />
          <Route exact path="/Terms_condition" component={Terms_condition} />
          <Route exact path="/Privacy" component={Privecy} />
          <Route exact path="/AboutUs" component={AboutUs} />
          <Route exact path="/ContactUs" component={ContactUs} />
          {/* <Route exact path="/OnlineAdmission" component={OnlineAdmission} />
          <Route exact path="/OnlineAdmissionNotice" component={OnlineAdmissionNotice} /> */}
          {/* <Route exact path="/ContactUs" component={ContactUs} />
          <Route exact path="/Registration" component={Registration} />
          <Route exact path="/PrintForm" component={PrintForm} />
          <Route exact path="/Courses" component={Courses} /> */}
        </Switch>
        </BrowserRouter>
      <Footer />
      </>
  );
}

export default App;
