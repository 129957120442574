import React, { Component } from 'react'
import { Button, Navbar, Nav, NavDropdown, Form, FormControl, Container } from 'react-bootstrap';

export default class Kanyashree extends Component {
    constructor(props) {
        super(props);
        this.state = {
            studentData: [],
            studentId:'',
            ksId :'',
        }
    }
     search=() =>{
        console.log('kkk');
        //S2017A567
        fetch(`https://haripurkosrmadrasah.org/sms_admin/api/fetchKsStudent.php?stId=${encodeURIComponent(this.state.studentId)}&ksId=${encodeURIComponent(this.state.ksId)}`
        //,{
            //'Sec-Fetch-Mode': 'cors',
            // headers:{
            //     'Access-Control-Allow-Credentials': false,
            // }
        //}
        )
                        .then(res => res.json())
                            .then(json => {
                                console.log(json);
                                if(json.length>0){
                                    
                                    this.setState({ 
                                        studentData:json
                                    })
                                }
                                else{
                                    alert("No Record Found");
                                }
                            }).catch((err) => {
                                this.setState({ 
                                    studentData:''
                                })
                                alert("No Record Found");
                              console.log(err);
                            });
    }
    handleInputChange = event=>{
        this.setState({
            [event.target.name]: event.target.value
        }, function(){
            console.log('st',this.state.studentId)
            console.log('ks',this.state.ksId)

        });
        if(event.target.name=='ksId'){
            this.setState({
                studentId: ''
            });
        }else{
            this.setState({
                ksId: ''
            });
        }
    }
  render() {
    return (
      <div style={{padding:'40px'}}>
          <div style={{width:'400px'}}>

                    <div >
                            <label for="exampleInputEmail1">Student ID</label>
                            <input type="text" class="form-control" id="studentId" name="studentId" aria-describedby="emailHelp" onChange={this.handleInputChange} placeholder="Student ID" style={{    width: '150px'}} value={this.state.studentId} />
                    </div>
                    <div style={{    float: 'right',position: 'absolute',marginTop: '-70px',marginLeft: '188px'}}>
                        <label for="exampleInputEmail1">Kanyashree ID</label>
                        <input type="text" class="form-control" id="ksId" name="ksId" aria-describedby="emailHelp" onChange={this.handleInputChange} placeholder="Kanyashree ID" style={{    width: '190px'}} value={this.state.ksId} />
                        <button  class="btn btn-primary" style = {{    marginTop: '-67px',   marginLeft: '227px'}} onClick={this.search}>Search</button>
                    </div>
          </div>
          <div style={{padding:'100px'}}>
          {
                      (this.state.studentData)?(
                      this.state.studentData.map((student,i) => {
                           return(  
                               
                                <div class="card" style={{width: '60rem'}}>
                                    <div class="card-body"> 
                                        
                                        <table id="ksTable">
                                            <tr>
                                                <td id="ksTableLavel">Kanyashree ID:</td>
                                                <td id="ksTableData" width={300}>{student.kanyashreeID}</td>
                                                <td id="ksTableLavel">Student ID:</td>
                                                <td id="ksTableData">{student.student_id}</td>
                                            </tr>
                                            <tr>
                                                <td id="ksTableLavel">Name:</td>
                                                <td id="ksTableData" width={300}>{student.stu_name}</td>
                                                <td id="ksTableLavel">Father Name:</td>
                                                <td id="ksTableData">{student.father_name}</td>
                                            </tr>
                                            <tr>
                                                {/* <td id="ksTableLavel">Mother Name:</td>
                                                <td id="ksTableData">{student.mother_name}</td> */}
                                                <td id="ksTableLavel">dob:</td>
                                                <td id="ksTableData" width={300}>{student.dob}</td>
                                               
                                            </tr>
                                            {/* <tr>
                                                <td id="ksTableLavel">Address:</td>
                                                <td id="ksTableData">{student.addr}</td>
                                                <td id="ksTableLavel">Contact:</td>
                                                <td id="ksTableData" width={300}>{student.contact1}</td>
                                               
                                            </tr> */}
                                            <tr>
                                                <td id="ksTableLavel">Session:</td>
                                                <td id="ksTableData" width={300}>{student.sessionName}</td>
                                                <td id="ksTableLavel">Class:</td>
                                                <td id="ksTableData">{student.class_name}</td>
                                            </tr>
                                            <tr>
                                                 <td id="ksTableLavel">Sec:</td>
                                                <td id="ksTableData" width={300}>{student.sec}</td>
                                                <td id="ksTableLavel">Roll:</td>
                                                <td id="ksTableData">{student.roll}</td>
                                            </tr>
                                            <tr>
                                                <td colSpan={4} style={{textAlign: 'center',fontSize: '19px',fontWeight: '700', color: '#e038d0'}}>
                                                    Kanyashree Info</td>
                                            </tr>
                                            <tr>
                                                <td id="ksTableLavel">Kanyashree Type:</td>
                                                <td id="ksTableData">{student.kanyashree_type}</td>
                                                <td id="ksTableLavel">KS Session:</td>
                                                <td id="ksTableData" width={300}>{student.kanyashree_st_session}</td>
                                               
                                            </tr>
                                            <tr>
                                                <td id="ksTableLavel">Kanyashree Document Receive Date:</td>
                                                <td id="ksTableData">{student.kanyashreeDocRecDate}</td>
                                                <td id="ksTableLavel">Kanyashree Update Date:</td>
                                                <td id="ksTableData" width={300}>{student.kanyashreeUpdateDate}</td>
                                                
                                            </tr>
                                            <tr>
                                                <td id="ksTableLavel">Remark:</td>
                                                <td id="ksTableData" colSpan={3}>{student.kanyashreeRemark}</td>
                                               
                                                
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                           )
                        })
                      ):''
                    }
           
          </div>
      </div>
    )
  }
}
